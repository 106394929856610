<template>
  <div class="privacy">

    <h2>Privacy Policy for AIRinsights</h2>

    Effective Date: 2024-02-20

    <p>This Privacy Policy describes how AIRinsights, a service provided by Boltflow, LLC (doing business as AIRinsights), collects, uses, and protects your information when you use our app.</p>

    <h3>Information We Collect</h3>

    <ul>
      <li>We collect information you provide directly to us, such as when you create an account or contact us for support.</li>
      <li>We automatically collect certain information when you use the app, including your device information and usage data.</li>
    </ul>

    <h3>How We Use Your Information</h3>

    <ul>
      <li>To provide and improve the AIRinsights app.</li>
      <li>To communicate with you about the app, including updates and support.</li>
      <li>For security purposes and to protect our rights.</li>
    </ul>

    <h3>Sharing Your Information</h3>

    <ul>
      <li>We do not share your personal information with third parties, except as required by law or to protect our rights.</li>
    </ul>

    <h3>Your Choices</h3>

    <ul>
      <li>You can update your account information or deactivate your account by contacting us.</li>
      <li>You may also have rights to access, correct, or delete your personal information.</li>
    </ul>

    <h3>Changes to This Privacy Policy</h3>

    <ul>
      <li>We may update this policy from time to time. We will notify you of any changes by posting the new policy on this page.</li>
    </ul>

    <h3>Contact Us</h3>
    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@boltflow.io">support@boltflow.io</a>.</p>

  </div>
</template>

<script>
</script>

<style scoped lang="scss">
  .privacy {
    padding: 50px
  }
  .privacy ul, .privacy p {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .privacy h3 {
    font-size: 1.2rem;
  }
  .privacy h2 {
    font-size: 1.7rem;
  }
</style>
