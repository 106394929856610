import { createRouter, createWebHistory } from 'vue-router'

// layouts

import MainLayout from '../views/layouts/Main.vue'

import AirtableSuccess from '../views/AirtableSuccess.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'

// pages

const routes = [
  {
    path: '/static/connection-success/airtable',
    name: 'AirtableSuccess',
    component: AirtableSuccess
  },
  {
    path: '/static/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/static/terms',
    name: 'Terms',
    component: Terms
  }
]

/**

TODO this may be useful in a future

import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import ResetPassword from '../views/ResetPassword.vue'

import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/',
    name: 'MainLayout',
    redirect: '/home',
    component: MainLayout,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
  
**/
  
/*,
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" * '../views/About.vue')
    }
  }
]
*/

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
