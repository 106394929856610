import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { auth } from "@/firebase.js"
import { onAuthStateChanged } from 'firebase/auth'

import './assets/styles/app.scss'
import 'bootstrap'

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth) {
    onAuthStateChanged(auth, (user) => {
      if (!user) next('/login')
      else next();
    })
  } else {
    next()
  }
})

createApp(App).use(router).mount('#app')
