import { initializeApp } from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'

import { getAuth, connectAuthEmulator } from 'firebase/auth'
import {
  getFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_APP_ID
}

const app = initializeApp(firebaseConfig)

const auth = getAuth()
const db = getFirestore()

if (process.env.VUE_APP_ENV.toUpperCase() !== 'PRODUCTION') {

  connectAuthEmulator(
    auth,
    'http://localhost:9099'
  )

  connectFirestoreEmulator(db, 'localhost', '8080')
}

export { db, auth }
export default app
