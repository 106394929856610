<template>
  <div class="terms">

    <h2>Terms of Service for AIRinsights</h2>

    Effective Date: 2024-02-20

    <p>Welcome to AIRinsights, a service provided by Boltflow, LLC ("we," "our," or "us"), doing business as AIRinsights. By using our app, you agree to be bound by the following terms and conditions (the "Terms of Service").</p>

    <h3>1. Use of Our Service</h3>

    <ul>
      <li>You may use our service only as permitted by law and these Terms of Service.</li>
      <li>We may suspend or stop providing our service if you do not comply with our terms or policies.</li>
    </ul>

    <h3>2. Your Account</h3>

    <ul>
      <li>You may need an account to use some features of our service. Keep your password confidential to protect your account.</li>
      <li>You are responsible for the activity that happens on or through your account.</li>
    </ul>

    <h3>3. Privacy</h3>

    <ul>
      <li>Our Privacy Policy explains how we handle your personal data and protect your privacy when you use our service.</li>
    </ul>

    <h3>4. Content</h3>

    <ul>
      <li>You retain all rights to the content you submit to the app. However, by submitting content, you grant us a license to use, host, store, reproduce, modify, and create derivative works.</li>
    </ul>

    <h3>5. Software</h3>

    <ul>
      <li>We give you a personal, worldwide, royalty-free, non-assignable, and non-exclusive license to use the software provided to you by us as part of the service.</li>
    </ul>

    <h3>6. Modifications to the Service and Prices</h3>

    <ul>
      <li>We may modify or discontinue the service at any time without notice.</li>
      <li>Prices for our service are subject to change without notice.</li>
    </ul>

    <h3>7. Termination</h3>

    <ul>
      <li>You can stop using our services at any time. We may also stop providing services to you or add or create new limits to our services at any time.</li>
    </ul>

    <h3>8. Disclaimer and Limitation of Liability</h3>

    <ul>
      <li>Our service is provided "as is," and we make no specific promises or guarantees about this service.</li>
      <li>To the extent permitted by law, we exclude all warranties and the liability for damages is limited.</li>
    </ul>

    <h3>9. Governing Law</h3>

    <ul>
      <li>These Terms will be governed by the laws of the United States of America without regard to its conflict of law provisions.</li>
    </ul>

    <h3>10. Changes to the Terms</h3>

    <ul>
      <li>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page.</li>
    </ul>

    <h3>11. Contact Us</h3>
    <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@boltflow.io">support@boltflow.io</a>.</p>

  </div>
</template>

<script>
</script>

<style scoped lang="scss">
  .terms {
    padding: 50px
  }
  .terms ul, .terms p {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .terms h3 {
    font-size: 1.2rem;
  }
  .terms h2 {
    font-size: 1.7rem;
  }
</style>
