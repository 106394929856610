<template>
  <div class="connection-success">
    <div style="text-align: center;"><img src="../assets/aritable_basebrain_chatting.png" width="200" /></div>
    <p style="padding-top: 50px; font-family: 'Inter', sans-serif; text-align: center">Airtable successfully connected! <strong>You can start chatting now.</strong></p>
    <p style="font-family: 'Inter', sans-serif; text-align: center">Go back to your GPT session, or start a new chat <a href='https://chat.openai.com/g/g-xhYxAV9Bz-airinsights'>here</a>.</p>
  </div>
</template>

<script>
</script>

<style scoped lang="scss">
  .connection-success {
    padding-top: 100px
  }
</style>
